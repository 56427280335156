import * as React from "react"

// pb-18 md:pb-24 lg:pb-28

const TextBlock = ({ children }) => (
  <>
    <article className="max-w-5xl mx-auto px-10 lg:px-20  flex flex-col">
      {children}
    </article>
  </>
)

export default TextBlock
