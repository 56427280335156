import React from "react";

const Short = ({
  link,
  text,
  className = "",
  short_id = "",
  tinao = false,
}) => {
  let ftText = text
  if (tinao) {
    ftText = ftText.replace('ТиНАО', '<span style="text-transform: none;">ТиНАО</span>');
  }

  const createText = () => {
    return {__html: ftText};
  }

  return (
    <div
      className={`float-right rounded-[7px] m-5 text-lg hidden md:block ${className}`}
    >
      <div
        className={`min-w-[250px] min-h-[300px] short-position-1 rounded-[5px] my-2 flex flex-col justify-end upEffect ${short_id} relative`}
      >
        <div className="bg-gradient-to-t from-black to-transparent p-5 pt-40 rounded-[7px]">
          <a
            className="short-link font-extrabold uppercase text-white short-link--full"
            href={link}
            dangerouslySetInnerHTML={createText()}
          >
          </a>
        </div>
      </div>
    </div>
  );
};

export default Short;

// <div className="float-right rounded-[7px] m-5 text-lg pt-20 hidden md:block">
//             <div className="bg-[url(../images/shorts/6c567260374145d66484ace4a0c49ccb-015afa0f.jpg)] w-[250px] h-[300px] short-position-1 rounded-[5px] my-2 flex flex-col justify-end">
//               <div className="bg-gradient-to-t from-black to-transparent p-5 pt-40 rounded-[7px]">
//                 <a
//                   className="short-link font-extrabold uppercase text-white "
//                   href="https://www.mos-gaz.ru/projects/482"
//                 >
//                   Реконструкция газопроводов-дюкеров
//                 </a>
//               </div>
//             </div>
//           </div>
