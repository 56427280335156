import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const Hero = ({ children }) => (
  <>
    <article className="max-w-5xl mx-auto px-10 lg:px-20 pt-24 md:pt-27 lg:pt-36 pb-10 flex flex-col justify-end text-4xl md:text-6xl lg:text-7xl tracking-tight font-semibold relative">
      <div className="absolute top-5 left-10 lg:left-20 z-50">
        <Link to="/">
          <StaticImage
            src="../images/logo.mini.svg"
            loading="eager"
            alt=""
            placeholder="none"
            width={125}
          />
        </Link>
      </div>
      <div className="custom-shape-divider-top-1661709281">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path d="M1200 0L0 0 598.97 114.72 1200 0z" class="shape-fill"></path>
        </svg>
      </div>

      <div className="custom-shape-divider-top-1661709281 header-shape--high-left">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path d="M1200 0L0 0 598.97 114.72 1200 0z" class="shape-fill"></path>
        </svg>
      </div>

      <div className="custom-shape-divider-top-1661716876">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path d="M1200 0L0 0 598.97 114.72 1200 0z" class="shape-fill"></path>
        </svg>
      </div>

      <div className="header-shape--high-right">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path d="M1200 0L0 0 598.97 114.72 1200 0z" class="shape-fill"></path>
        </svg>
      </div>

      <div className="custom-shape-divider-top-1661710634">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path d="M1200 0L0 0 598.97 114.72 1200 0z" class="shape-fill"></path>
        </svg>
      </div>
      <div
        className="
          mt-32 lg:mt-24 
          sm:max-w-lg md:max-w-xl lg:max-w-none
          text-2xl sm:text-3xl md:text-4xl lg:text-5xl"
      >
        {children}
      </div>
    </article>
  </>
);

// id="banner"
const s = `<div className="hero-romb hero-romb--first"></div>
<div className="hero-romb hero-romb--second"></div>`;

export default Hero;
