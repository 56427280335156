import * as React from "react";
import * as d3 from "d3";

const colors = [
  "#ee6e21",
  "#da6b2b",
  "#c66733",
  "#b2633a",
  "#9e5f40",
  "#8a5b46",
  "#76574b",
  "#60524f",
  "#484d54",
  "#2a4858",
];

const getNextColor = (colors) => {
  let i = 0;

  return () => {
    if (i === colors.length) {
      i = 0;
    }
    return colors[i++];
  };
};

const tonPie = [
  {
    label: "Позитивный",
    value: 88,
  },
  {
    label: "Негативный",
    value: 12,
  },
];

const data = tonPie;

const PieNeo4 = ({ idkey, pieColors = colors }) => {
  React.useEffect(() => {
    const selector = `.pieOne-${idkey}`;
    document.querySelector(selector).innerHTML = "";
    const colorInstans = getNextColor(pieColors);

    const width = 300;
    const height = 300;
    const margin = 20;
    const radius = Math.min(width, height) / 2 - margin;

    // data.sort((a, b) => {
    //   if (a.value < b.value) {
    //     return 1
    //   }
    //   return -1
    // })

    const dataEntries = data.map((i) => {
      return [i.label, i.value];
    });

    const svg = d3
      .select(`.pieOne-${idkey}`)
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("transform", `translate(${width / 2},${height / 2})`);

    // Compute the position of each group on the pie:
    const pie = d3
      .pie()
      .sort(null)
      .value((d) => d[1]);

    const data_ready = pie(dataEntries);

    // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
    svg
      .selectAll()
      .data(data_ready)
      .join("path")
      .attr(
        "d",
        d3
          .arc()
          .innerRadius(radius * 0.5) // This is the size of the donut hole
          .outerRadius(radius * 0.7)
      )
      .attr("fill", colorInstans)
      .attr("stroke", "#fff")
      .style("stroke-width", "1px");

    const arc = d3
      .arc()
      .innerRadius(radius * 0.5) // This is the size of the donut hole
      .outerRadius(radius * 0.8);

    const outerArc = d3
      .arc()
      .innerRadius(radius * 0.9)
      .outerRadius(radius * 1.2);

    svg
      .selectAll("allPolylines")
      .data(data_ready)

      .join("polyline")

      .attr("stroke", "black")
      .style("fill", "none")
      .attr("stroke-width", 0.4)
      .attr("points", (d, i, a) => {
 

        const posA = arc.centroid(d); // line insertion in the slice
        const posB = outerArc.centroid(d); // line break: we use the other arc generator that has been built only for that
        const posC = outerArc.centroid(d); // Label position = almost the same as posB
        const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2; // we need the angle to see if the X position will be at the extreme right or extreme left
        posC[0] = posC[0] + 30 * (midangle < Math.PI ? 1 : -1); // multiply by 1 or -1 to put it on the right or on the left

        return [posA, posB, posC];
      });

    svg
      .selectAll("allLabels")
      .data(data_ready)
      .join("text")
      .text((d) => d.data[1] + "%")
      .attr("transform", (d, i, a) => {
        const pos = outerArc.centroid(d);
        const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
        pos[1] += -3;
        pos[0] += 5 * (midangle < Math.PI ? 1 : -1);

        return `translate(${pos})`;
      })
      .style("text-anchor", function (d) {
        const midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
        return midangle < Math.PI ? "start" : "end";
      });
  });

  return (
    <div className="pieOne--wrapper flex flex-col-reverse justify-center items-center mb-5">
      <ul className="list-none pl-0 text-300 my-0 text-sm flex flex-row gap-5">
        {data
          .slice()
          .map((el, i) => {
            el.color = pieColors[i];
            return el;
          })
          // .sort((a, b) => {
          //   return (a.value - b.value) * -1;
          // })
          .map((el, i) => (
            <li key={i}>
              <span
                className={`legend-pseudo`}
                style={{ "background-color": el.color }}
              ></span>
              {el.label}
            </li>
          ))}
      </ul>
      <div className={`pieOne-${idkey}`}></div>
    </div>
  );
};

export default PieNeo4;
