import * as React from "react";

const baseUrl = `https://vk.com/js/api/openapi.js?169`;
const VKPost = ({ elementId, ownerId, postId, hash, children }) => {
  const [scriptLoaded, setScriptLoaded] = React.useState(false);

  elementId = elementId || `vk_post_${ownerId}_${postId}`;

  React.useEffect(() => {
    const removeFallback = () => {
      const element = document.querySelector(`#${elementId}`);
      console.log(element)
      if (element) {
        while (element.firstChild) {
          element.firstChild.remove();
        }
      }
    };

    const vkScriptElement = document.querySelector("#vk-script-element");
    if (!vkScriptElement) {
      const script = document.createElement("script");
      script.id = "vk-script-element";
      script.src = baseUrl;
      script.async = true;
      // script.timeout = '2s'

      script.addEventListener("load", (evt) => {
        console.log(evt);
        removeFallback();
        setScriptLoaded(true);
      });

      document.head.appendChild(script);
      // onload fallback
    } else {
      vkScriptElement.addEventListener("load", (evt) => {
        console.log(evt);
        removeFallback();
        setScriptLoaded(true);
      });
    }

    const cleanup = () => {
      const vkElement = document.getElementById(elementId);
      if (vkElement) vkElement.remove();
    };

    return cleanup;
  }, [elementId, ownerId, postId, hash]);

  React.useEffect(() => {
    try {
      if ("VK" in window) {
        window.VK.Widgets.Post(elementId, ownerId, postId, hash);
      }
    } catch (error) {
      console.log(error);
      // render link
    }
  }, [scriptLoaded]);

  return <div id={elementId} className="max-w-[550px]">{children}</div>;
};

export default VKPost;
