import React from "react";
import { Link } from "gatsby";

const NaviNext = ({ next, title, native = false }) => {
  const nextLink = native ? (
    <a href={next}>{title}</a>
  ) : (
    <Link to={next}>{title}</Link>
  );

  return (
    <nav className="flex flex-col md:flex-row gap-2 md:gap-5 justify-left my-5">
      <Link to="/">Оглавление</Link>
      { nextLink }
    </nav>
  );
};

export default NaviNext;
