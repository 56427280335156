import * as React from "react";
import PropTypes from "prop-types";
import { slide as Menu } from "react-burger-menu";
import { Link } from "gatsby";
import { getLink } from "./helper";
import { useSiteMetadata } from "../hooks/use-site-metadata";

import "../css/index.css";

const Tail = ({ children }) => {
  const { siteUrl, pathPrefix } = useSiteMetadata();

  return (
    <>
      <Menu
        slide="reveal"
        right={true}
        width={"50%"}
        className="min-w-[300px]"
        pageWrapId={"page-wrap"}
      >
        <div className="burgerMenu-item">
          <Link to="/part-0">ОБРАЩЕНИЕ ГЕНЕРАЛЬНОГО ДИРЕКТОРА ОБЩЕСТВА</Link>
        </div>

        <div className="burgerMenu-item">
          <Link to="/part-1">РАЗДЕЛ 1. ОБЩИЕ СВЕДЕНИЯ</Link>
        </div>

        <div className="burgerMenu-item">
          <Link to="/part-2">
            РАЗДЕЛ 2. ОСНОВНЫЕ ПРОИЗВОДСТВЕННЫЕ ПОКАЗАТЕЛИ
          </Link>
        </div>

        <div className="burgerMenu-item">
          <Link to="/part-3">РАЗДЕЛ 3. ИНВЕСТИЦИОННАЯ ДЕЯТЕЛЬНОСТЬ</Link>
        </div>

        <div className="burgerMenu-item">
          <Link to="/part-4">
            РАЗДЕЛ 4. ОСНОВНЫЕ ПОКАЗАТЕЛИ ФИНАНСОВО-ХОЗЯЙСТВЕННОЙ ДЕЯТЕЛЬНОСТИ
          </Link>
        </div>

        <div className="burgerMenu-item">
          <Link to="/part-5">РАЗДЕЛ 5. КОРПОРАТИВНОЕ УПРАВЛЕНИЕ</Link>
        </div>

        <div className="burgerMenu-item">
          <Link to="/part-6">
            РАЗДЕЛ 6. ИНФОРМАЦИЯ ОБ ИМУЩЕСТВЕННОМ КОМПЛЕКСЕ
          </Link>
        </div>

        <div className="burgerMenu-item">
          <Link to="/part-7">РАЗДЕЛ 7. КАДРОВАЯ И СОЦИАЛЬНАЯ ПОЛИТИКА</Link>
        </div>

        <div className="burgerMenu-item">
          <Link to="/part-8">
            РАЗДЕЛ 8. ПРОИЗВОДСТВЕННАЯ БЕЗОПАСНОСТЬ И ЭКОЛОГИЧЕСКАЯ
            ОТВЕТСТВЕННОСТЬ
          </Link>
        </div>

        <div className="burgerMenu-item">
          <Link to="/part-9">РАЗДЕЛ 9. ОСВЕЩЕНИЕ ДЕЯТЕЛЬНОСТИ АО «МОСГАЗ»</Link>
        </div>

        <div className="burgerMenu-item">
          <Link to="/part-10">РАЗДЕЛ 10. СПРАВОЧНАЯ ИНФОРМАЦИЯ</Link>
        </div>

        <div className="burgerMenu-item">
          <Link to="/app-1">ПРИЛОЖЕНИЕ 1</Link>
        </div>

        <div className="burgerMenu-item">
          <Link
            to={getLink({
              siteUrl,
              pathPrefix,
              filename: "/mos-gaz.accounting.statements.2021.pdf",
            })}
            native={true}
          >
            ПРИЛОЖЕНИЕ 2 (PDF) Бухгалтерская отчётность
          </Link>
        </div>
        <div className="h-5 w-full"></div>
      </Menu>
      <main className="w-screen overflow-hidden">
        {children}
      </main>
      <footer className="footer relative">
        <div class="custom-shape-divider-bottom-1661706721 footer-shape-first">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M1200 0L0 0 892.25 114.72 1200 0z"
              class="shape-fill"
            ></path>
          </svg>
        </div>

        <div class="custom-shape-divider-bottom-1661706721 footer-shape--low-left">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M1200 0L0 0 892.25 114.72 1200 0z"
              class="shape-fill"
            ></path>
          </svg>
        </div>

        <div class="custom-shape-divider-bottom-1661707572">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M1200 0L0 0 598.97 114.72 1200 0z"
              class="shape-fill"
            ></path>
          </svg>
        </div>

        <div class="custom-shape-divider-bottom-1661707572 footer-shape--low-right">
          <svg
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M1200 0L0 0 598.97 114.72 1200 0z"
              class="shape-fill"
            ></path>
          </svg>
        </div>
        <p className="content-center absolute right-12 bottom-0 font-semibold">АО «МОСГАЗ» &copy; 2022</p>
      </footer>
    </>
  );
};

Tail.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Tail;
